import "../App.css";
import { useEffect, useState, useRef } from "react";

import { ReactComponent as IcoSparks } from "../assets/images/sparkgroup.svg";

import axios from "axios";
import _ from "lodash";
import { useHistory } from "react-router-dom";

const API_BASE = "https://display.activaire.com/api";
// const API_BASE = "http://localhost:1337";

function NowPlaying() {
  const history = useHistory();
  const [song, setSong] = useState({
    title: "",
    artist: "",
    album: "",
    artworkUrl: "",
    nowPlayingName: "",
  });

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  const colors = _.shuffle([
    "#3B46AB",
    "#3B7DAB",
    "#670BCE",
    "#0B72CE",
    "#E96187",
    "#E961CB",
    "#C361E9",
    "#7F61E9",
    "#6187E9",
    "#FFBF00",
    "#FF4000",
    "#FF0040",
    "#0B11CE",
    "#3BAB69",
    "#0BCEC9",
    "#0BCE69",
  ]);
  const [bgColor, setBgColor] = useState(colors[0]);
  const [boxColor, setBoxColor] = useState(colors[colors.length - 1]);

  useInterval(() => {
    // Your custom logic here
    axios
      .post(API_BASE, { id: window.location.pathname.replace("/", "") })
      .then((res) => {
        setSong(res.data);
      });
  }, 5000);

  useEffect(() => {
    const randomColors = _.sampleSize(colors, 2);
    setBgColor(randomColors[0]);
    setBoxColor(randomColors[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [song.title]);

  useEffect(() => {
    axios
      .post(API_BASE, { id: window.location.pathname.replace("/", "") })
      .then((res) => {
        if (res.data.errorName) {
          history.push("/");
        } else {
          setSong(res.data);
        }
      });
  }, []);

  return (
    <div
      className="wrapper"
      style={{
        backgroundColor: bgColor,
      }}
    >
      <span className="spanbg"></span>
      <div className="container">
        <div className="artwork-container">
          <IcoSparks className="sparks" />
          <span
            className="first-box"
            style={{ backgroundColor: boxColor }}
          ></span>

          <img
            src={
              song.artworkUrl ||
              require("../assets/images/offline1.png").default
            }
            alt="img"
          />
        </div>

        <div className="metadata-container">
          <h1 style={{ fontSize: song?.title.length > 60 ? 70 : 90 }}>
            {song.title}
          </h1>
          <p>{song.artist}</p>
          <p style={{ opacity: 0.5 }}>{song.nowPlayingName}</p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 250,
              width: 500,
              justifyContent: "space-between",
            }}
          >
            <img alt="img2" className="logo-image" src={song.mainLogo} />
            {song.secondaryLogo && (
              <>
                <span style={{ fontSize: 31, color: "#FFFFFF80" }}>+</span>
                <img
                  alt="img2"
                  className="logo-image"
                  src={song.secondaryLogo}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NowPlaying;
