import React from "react";
import { Switch, Route } from "react-router-dom";

import NowPlaying from "./screens/NowPlaying";
import CodeScreen from "./screens/CodeScreen";

const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact>
        <CodeScreen />
      </Route>
      <Route path="/:id" exact>
        <NowPlaying />
      </Route>
    </Switch>
  );
};

export default Routes;
