import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import ReactCodeInput from "@acusti/react-code-input";

import { ReactComponent as CuratorLogo } from "../assets/images/curatorlogo.svg";
import { ReactComponent as OK } from "../assets/images/ok.svg";
import { ReactComponent as NOTOK } from "../assets/images/notok.svg";

import axios from "axios";
import _ from "lodash";

const API_BASE = "https://display.activaire.com/api";

const colors = ["#9B51E0", "#219653", "#EB5757"];
const CodeScreen = () => {
  const history = useHistory();

  const statusArray = [
    { title: "pending", color: colors[0], icon: null, message: null },
    { title: "valid", color: colors[1], icon: <OK />, message: null },
    {
      title: "invalid",
      color: colors[2],
      icon: <NOTOK />,
      message: (
        <h1
          style={{
            marginTop: 20,
            fontSize: 36,
            textAlign: "center",
            maxWidth: "100%",
          }}
        >
          Incorrect code, please try again.
        </h1>
      ),
    },
  ];

  const [codeStatus, setCodeStatus] = useState(statusArray[0]);
  const [inputCode, setInputCode] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (inputCode.length === 4) {
      setIsDisabled(true);
      axios.post(API_BASE, { id: inputCode.toLowerCase() }).then((res) => {
        if (res.data.errorName) {
          setCodeStatus(statusArray[2]);
          setTimeout(() => {
            setIsDisabled(false);
            setInputCode("");
            setCodeStatus(statusArray[0]);
          }, 1000);
        } else {
          setCodeStatus(statusArray[1]);
          setTimeout(() => {
            history.push(`/${inputCode}`);
          }, 1500);
        }
      });
    }
  }, [inputCode]);

  return (
    <div
      className="wrapper"
      style={{
        backgroundColor: codeStatus.color,
      }}
    >
      <span className="spanbg"></span>
      <div className="code-container">
        <CuratorLogo />
        <h1>
          Weclome to Activaire Curator’s Display Mode. Please enter your pairing
          code.
        </h1>
        <ReactCodeInput
          className="reactcodeinput"
          type="text"
          fields={4}
          disabled={isDisabled}
          value={inputCode}
          autoFocus={true}
          onChange={(value) => setInputCode(value)}
          inputStyle={{
            fontFamily: "Tondo",
            fontWeight: "bold",
            margin: "8px",
            MozAppearance: "textfield",
            width: "100px",
            fontSize: "96px",
            height: "144px",
            backgroundColor: "#F2F2F2",
            color: "#000000",
            borderColor: "transparent",
            textTransform: "uppercase",
            textAlign: "center",
            borderRadius: 15,
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        {codeStatus.icon}
        {codeStatus.message}
      </div>
    </div>
  );
};

export default CodeScreen;
